export const TipoImportacaoEnum = {
  "RH_FOPAG": 1,
  "RH_TIMESHEET": 2,
  "RH_PROVISOES": 3,
  "FATURAMENTO_NF_XML": 4,
  "FISCAL_NF_MATERIAIS": 5,
  "RH_PONTO": 6,
  "FATURAMENTO_PRODUTOS_INCENTIVADOS": 7,
  "FATURAMENTO_CATALOGO_ITENS": 8,
  "FISCAL_NF_TERCEIROS": 9,
  "ANEXO_NF_MATERIAIS": 10,
  "ANEXO_NF_SERVICOS_TERCEIROS": 11,
  "FISCAL_NF_CONSUMO_MATERIAIS": 12,
  "ACESSO_USUARIOS_SISTEMA": 13,
  "FISCAL_RAZAO_CONTABIL": 14,
  "FATURAMENTO_NF_CANCELADAS": 15,
  "FATURAMENTO_CATALOGO_ITENS_COMPLEMENTAR": 16,
  "COLABORADORES": 17,
};

export const StatusImportacaoEnum = {
  ERRO: -1,
  AGUARDANDO: 1,
  PROCESSANDO: 2,
  CONCLUIDO: 3,
};

export const StatusImportacaoTranslationEnum = {
  [StatusImportacaoEnum.ERRO]:  "Erro",
  [StatusImportacaoEnum.AGUARDANDO]: "Na fila",
  [StatusImportacaoEnum.PROCESSANDO]: "Em processamento",
  [StatusImportacaoEnum.CONCLUIDO]: "Concluído",
  ERRO:  "Erro",
  AGUARDANDO: "Na fila",
  PROCESSANDO: "Em processamento",
  CONCLUIDO: "Concluído",
};
